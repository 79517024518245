import React from 'react';

import * as S from './style';
import getThemeColor from '../../utils/getThemeColor';

type RecommendedPostsProps = {
  next?: {
    frontmatter: {
      title: string;
    };
    fields: {
      slug: string;
    };
  };
  previous?: {
    frontmatter: {
      title: string;
    };
    fields: {
      slug: string;
    };
  };
};

const RecommendedPosts = ({ next, previous }: RecommendedPostsProps) => (
  <S.RecommendedWrapper>
    {previous && (
      <S.RecommendedLink
        cover
        direction="right"
        bg={getThemeColor()}
        duration={0.6}
        to={previous.fields.slug}
        className="previous"
        full={(!next).toString()}
      >
        {previous.frontmatter.title}
      </S.RecommendedLink>
    )}
    {next && (
      <S.RecommendedLink
        cover
        direction="left"
        bg={getThemeColor()}
        duration={0.6}
        to={next.fields.slug}
        className="next"
        full={(!previous).toString()}
      >
        {next.frontmatter.title}
      </S.RecommendedLink>
    )}
  </S.RecommendedWrapper>
);

export default RecommendedPosts;
